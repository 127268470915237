.block {
    width: 100%;
}
.propertiesVersionForm {
    margin-bottom: 20px !important;
}
.modalWrapper {
    width: 100%;
    height: 414px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.titleInvite {
    display: flex;
    text-align: center;
}
.modalContainer {
    width: 414px;
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.modalContainerCopyProperties {
    width: 700px;
    padding: 60px 0 70px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.projectPropertyVersionNameWrapper {
    width: 440px;
}

.projectPropertyVersionName {
    width: 440px;
    height: 54px;
    border-radius: 10px;
}
