.inner {
    margin-top: 50px;
    width: 100%;
    margin-bottom: 100px;
}

.title {
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
}

.form {
    margin-top: 30px;
}

.inputInner {
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    width: 100%;
    background: #f9f9f9;
}

.buttonBlock {
    margin-top: 20px;
}
