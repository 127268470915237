.sliderRate .slick-arrow.slick-prev {
    /* top: 420px !important; */
    top: calc(50% - 25px);
    /* transform: translateX(-50%); */
}
.sliderRate .slick-dots {
    position: absolute !important;
    top: -50px !important;
    height: 20px;
}
