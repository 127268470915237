.webinarContentLabel {
    width: 100%;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 30px 0;
}

.webinarContent {
    width: 100%;
    margin: 0 0 40px 0;
}
.webinarContentEntity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px rgba(219, 221, 225, 1);
    min-height: 64px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.webinarContentEntityName {
    width: 820px;
    margin: 20px 0 20px 0;
}
.webinarContentEntitySize {
    color: rgba(126, 129, 140, 1);
    margin: 20px 0 20px 0;
}
.webinarContentEntityDownload {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: rgba(22, 109, 249, 1);
    margin: 20px 0 20px 0;
}
.webinarContentEntity:last-child {
    border-bottom: solid 1px rgba(219, 221, 225, 1);
}

.webinarTimeBlock {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}