.btn {
    width: 174px;
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 54px;
}

.img {
    margin-bottom: 50px;
}
