.cardsWrapper {
    display: flex;
    gap: 31px;
    margin-bottom: 100px;
}
.card {
    height: 330px;
    width: 540px;
    border-radius: 20px;
}
.blue {
    background-color: rgba(22, 109, 249, 1);
}
.white {
    background-color: rgba(255, 255, 255, 1);
}
.contentWrapper {
    display: inline-block;
    margin-top: 40px;
    margin: 40px 0 0 50px;
}
.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(238, 240, 244, 1);
    width: 65px;
    height: 65px;
    border-radius: 12px;
}
.mainText {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    margin: 25px 0 20px;
}
.secondText {
    width: 90%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.textWhite {
    color: white;
}
.textBlue {
    color: rgba(22, 109, 249, 1);
}
.contractLink {
    color: rgba(22, 109, 249, 1);
    cursor: pointer;
}
