* {
    font-family: 'Manrope';
}

.CreateProject .select > div, 
.PropertyProject .select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
}

.CreateProject .title,
.PropertyProject .title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 800;
    font-size: 36px;
    line-height: 46px;
    margin-top: 50px;
}

.CreateProject .blockCard,
.PropertyProject .blockCard {
    border: 1px solid #dbdde1;
    border-radius: 20px;
    max-width: 1010px;
    margin-top: 30px;
    padding: 40px 45px;
}

.CreateProject .blockCard:first-child,
.PropertyProject .blockCard:first-child {
    margin-top: 50px;
}

.CreateProject .titleCard,
.PropertyProject .titleCard {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;
}

.CreateProject .titleCard_item,
.PropertyProject .titleCard_item {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 40px;
}

.CreateProject .inputInner,
.PropertyProject .inputInner {
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    width: 100%;
    background: #f9f9f9;
}

.CreateProject .textArea,
.PropertyProject .textArea {
    height: auto;
    padding: 20px;
}

.CreateProject .inputInnerShort,
.PropertyProject .inputInnerShort {
    width: 320px;
}

.CreateProject .inputInnerShort2,
.PropertyProject .inputInnerShort2 {
    width: 490px;
}

.CreateProject .inputInnerShort4,
.PropertyProject .inputInnerShort4 {
    width: 220px;
}

.CreateProject .shortInnersBlock,
.PropertyProject .shortInnersBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.CreateProject .main,
.PropertyProject .main {
    width: 100%;
    min-width: 1110px;
}

.CreateProject .elementsBlock,
.PropertyProject .elementsBlock {
    display: flex;
    justify-content: space-between;
}

.CreateProject .filmInfo,
.PropertyProject .filmInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.CreateProject .filmInfo > div,
.PropertyProject .filmInfo > div {
    margin-bottom: 0;
}

.CreateProject .selectEl,
.PropertyProject .selectEl {
    width: auto;
}

.CreateProject .selectEl3,
.PropertyProject .selectEl3 {
    width: 320px;
}

.CreateProject .w100,
.PropertyProject .w100 {
    width: 100%;
}

.CreateProject .addBtn,
.PropertyProject .addBtn {
    display: flex;
    justify-content: start;
    margin-top: 20px;
}

.CreateProject .inputEpisode,
.PropertyProject .inputEpisode {
    margin-left: 20px;
    margin-right: 20px;
    width: 150px;
}

.CreateProject .subTitle,
.PropertyProject .subTitle {
    font-size: 16px;
    margin-top: 10px;
}

#serial_form_approx_timing_from_help > .ant-form-item-explain-error,
#serial_form_episode_timing_help > .ant-form-item-explain-error {
    margin-left: 20px;
}

.CreateProject .bucket, 
.PropertyProject .bucket {
    align-self: end;
    margin-bottom: 7px !important;
}

.CreateProject .devider, 
.PropertyProject .devider {
    background-color: #dbdde1;
    width: 1px;
    height: 200px;
}

.CreateProject .checkBlock,
.PropertyProject .checkBlock {
    display: flex;
    flex-wrap: wrap;
}

.CreateProject .checkBlock .ant-checkbox-wrapper,
.PropertyProject .checkBlock .ant-checkbox-wrapper {
    margin: 0 20px 20px 0;
}

.CreateProject .required-field::after, 
.PropertyProject .required-field::after {
    content: ' *';
    color: red;
    display: inline;
}

.mb0 {
    margin-bottom: 0 !important;
}