.headerTime {
    text-transform: capitalize;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 18px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f4f8;
    border-radius: 20px 20px 0 0;
    height: 95px;
    padding: 0 45px 0 50px;
    user-select: none;
}

.headerChangeMonths {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 115px;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f2f4f8;
    border: 1px solid #dbdde1;
    cursor: pointer;
    transition: 0.3s all;
}

.arrow:hover {
    background: #e9ebef;
}

.left {
    transform: rotate(180deg);
}
