.wrapper {
    height: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 1100px;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.background {
    position: absolute;
    width: 100%;
    height: 540px;
}

.svg {
    position: absolute;
    top: 115px;
    width: 95%;
    height: 380px;
}

.titleWrapper {
    z-index: 5;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 56px;
    color: #fff;
    line-height: 66px;
    font-weight: 800;
}

.mask {
    width: 250px;
}

.bottomMask {
    transform: rotate(180deg);
    margin-top: 30px;
}
