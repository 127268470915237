.wrapper {
    width: 350px;
    transition: 0.3s all;
    color: #000;
    position: relative;
    height: 340px;
}
.container {
    height: 260px;
}
.imgWrapper {
    border-radius: 20px;
    width: 100%;
    height: 260px;
    background-color: #fff;
}
.img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.textContainer {
    display: flex;
    background-color: #fff;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 0 30px 0 30px;
    height: 110px;
    margin-top: -36px;
    position: relative;
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text {
    margin-top: auto;
    margin-bottom: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.date {
    position: absolute;
    top: 10px;
    left: 30px;
}

.typeWeb {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 111;
}
.imgPlayer {
    position: absolute;
    top: 100px;
    left: 145px;
}
.subscription {
    cursor: pointer;
    position: absolute;
    background-color: #eef0f4;
    bottom: 40%;
    padding: 15px 20px;
    border-radius: 10px;
    color: #166df9;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    left: 10%;
    opacity: 0;
    transition: 0.5s;
}

.subscription.visible {
    opacity: 1;
}
.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
