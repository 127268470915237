.wrapper {
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 350px;
    height: 264px;
    min-height: 264px;
}

.icon {
    position: absolute;
    right: 30px;
    top: 30px;
}

.photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    user-select: none;
}

.personWrapper {
    display: flex;
}

.personDataWrapper {
    margin-left: 20px;
    height: 148px;
}

.btnsWrapper {
    display: flex;
    justify-content: space-between;
}

.addBtn {
    width: 54px;
    height: 54px;
    background-color: #eef0f4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.175s all;
}

.addBtn img {
    width: 20px;
    height: 20px;
}

.addBtn:hover {
    background-color: #e6e6e6;
}

.addBtn:active {
    background-color: #b8b8b8;
}
.personDataButtonWrapper {
    min-height: 54px;
}
.fio {
    width: 230px;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.email {
    width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
}
