.wrapper {
    width: 830px;
    height: 380px;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 35px 45px 0 35px;
    margin-bottom: 80px;
}

.container {
    display: flex;
}

.img {
    width: 70px;
    height: 70px;
    border-radius: 50% !important;
    margin-right: 30px;
}

.nameWrapper {
    margin-bottom: 39px;
}

.name {
    font-size: 20px;
    line-height: 26px;
    font-weight: 800;
}

.textWrapper {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}
