.wrapper {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    width: 1100px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 30px;
}

.breadcrumb > nav {
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.breadcrumb__btn {
    padding: 0 !important;
    font-size: 16px;
    font-weight: 600;
}

.noLectures {
    margin: 111px 0 171px 0;
}
.noLecturesText {
    margin: 10px 0 0 10px;
}
