.addGenreBlock {
    display: flex;
    flex-direction: column;
}

.bucket {
    margin-left: 15px;
}

.customGenreBlock {
    display: flex;
    align-items: baseline
}
