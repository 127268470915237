.file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
}

.paperclipIcon {
    margin: 6px 10px 0 0;
    cursor: pointer;
}

.upload {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
