.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.rowLeft {
    display: flex;
}

.text {
    width: 420px;
    min-width: 420px;
}
