.container {
    width: 1100px;
    display: flex;
    justify-content: left;
    margin: 0 auto;
    padding-top: 30px;
}

.breadcrumb > nav {
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
