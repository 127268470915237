.container {
    width: 1100px;
    justify-content: left;
    margin: 0 auto 45px auto;
}

.titleSubscrButton {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: space-between;
}

.title {
    font-size: 36px;
    font-weight: 800;
    line-height: 46px;
}

.subscribeButton {
    margin-top: 25px;
}

.renewSubscr {
    display: inline-flex;
    width: 420px;
    min-height: 60px !important;
    height: max-content;
    justify-content: space-between;
    align-items: space-between;
    border: solid 1px #dbdde1;
    border-radius: 10px;
    padding: 18px 12px 12px 12px;
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.right__block {
    display: flex;
    width: 445px;
    height: 400px;
    align-items: center;
    justify-content: center;
}

.main__leftside {
    display: flex;
    flex-direction: column;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}