.authForm .ant-form-item {
    margin-bottom: 17px !important;
}

.authForm .ant-form-item-label {
    padding-bottom: 0 !important;
}

.authForm .ant-form-item-required::before {
    content: '' !important;
}

.authForm .ant-form-item-required::after {
    content: '*' !important;
    display: inline-block !important;
}
