.button {
    font-size: 16px;
    line-height: 18px;
    color: #adb2c4;
    /* margin-right: 24px; */
    white-space: nowrap;
    cursor: pointer;
    transition: 0.175s all;
}

.button:hover {
    color: #797d8a;
}

.background {
    padding: 14px 20px;
    background-color: #eef0f4;
    color: #166df9;
    border-radius: 10px;
}

.background:hover {
    background-color: #dadce0;
    color: #166df9;
}
