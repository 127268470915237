.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    min-width: 50px;
    height: 50px;
    background-color: #166df9;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
}

.transparent {
    background-color: transparent;
}

.photo {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
}

.menuBtn {
    user-select: none;
    cursor: pointer;
    transition: 0.175s all;
    padding: 5px;
    border-radius: 10px;
}
.noCursor {
    cursor: default;
}
