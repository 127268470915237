.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.container {
    width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.enterCodeText {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 800;
    margin-bottom: 30px;
    font-family: 'Manrope';
}
.phoneNumber {
    width: 410px;
    height: 54px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Manrope';
    font-weight: 400;
}
.changeButton {
    position: absolute;
    right: 145px;
    top: 160px;
}
.smsCode {
    display: flex;
    justify-content: space-between;
    width: 410px;
}
.error {
    color: rgba(253, 0, 0, 1);
    margin-top: 10px;
}
.hideAttempts {
    display: none;
}
.hideError {
    display: none;
}
.focusedInput {
    border: 2px solid rgba(22, 109, 249, 1) !important;
}
.focusedInput:focus {
    border: 2px solid rgba(22, 109, 249, 1) !important;
}
.focusedInput:hover {
    border: 2px solid rgba(22, 109, 249, 1) !important;
}
.errorInput {
    border: 1px solid rgba(253, 0, 0, 1) !important;
}

.greyDigital {
    background-color: rgba(249, 249, 249, 1);
}

.smsCodeDigit input {
    width: 60px;
    height: 70px;
    font-size: 28px;
    text-align: center;
    font-family: 'Manrope';
    font-weight: 400;
}
.footer {
    width: 410px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 70px 0;
}
