.wrapper {
    display: flex;
    max-width: fit-content;
    width: 1110px;
}

.contactsBlock {
    width: 350px;
    /* height: 560px; */
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 30px;
    margin-right: 30px;
}

.email {
    color: #166df9;
    text-decoration: none;
}

.phone {
    color: inherit;
    text-decoration: none;
}

.writeUsBlock {
    width: 830px;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 30px;
}

.shortWriteUsBlock {
    height: min-content;
}

.map {
    margin-top: 30px;
    width: 1110px;
    height: 514px;
    border-radius: 20px;
    overflow: hidden;
}

.block {
    margin-bottom: 30px;
}

.block:last-child {
    margin-bottom: 0;
}

.block > img {
    height: 45px;
}

.form {
    margin-top: 30px;
}

.inputInner {
    height: 54px;
    width: 670px !important;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    width: 100%;
    background: #f9f9f9;
}

.inputInnerShort {
    height: 54px;
    width: 320px !important;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    width: 100%;
    background: #f9f9f9;
}

.formMessage {
    margin-top: 30px;
}

.file {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0px;
}

.paperclipIcon {
    margin-top: 6px;
    cursor: pointer;
}

.captchaAndButtonContainer {
    display: flex;
    flex-direction: row;
    width: 670px;
    flex-shrink: 0;
}

.agreement {
    display: flex;
    width: 670px;
    flex-shrink: 0;
}

.captchaContainer {
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
}

.buttonContainer {
    display: flex;
    align-items: center;
}
