.contragentsTable {
    width: 100%;
}

.contragentsTable .ant-table-cell {
    padding: 30px 16px !important;
}

.contragentsTable th.ant-table-cell {
    background-color: #fff !important;
    border-top: 2px solid #000 !important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope';
}

.contragentsTable th.ant-table-cell::before {
    background-color: #fff !important;
}

.contragentsTable table > thead > tr > th:nth-child(6) {
    text-align: right;
}

.contragentsTable table > tbody > tr > td:nth-child(6) {
    text-align: right;
}
