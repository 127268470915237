.docsTable .ant-table-cell {
    background-color: #eef0f4 !important;
}

.docsTable .ant-table-cell {
    padding: 20px 0 !important;
}

.docsTable table > tbody > tr > td:nth-child(2) > div > div {
    color: #7e818c !important;
}

.docsTable table > tbody > tr > td:nth-child(3) > div > div {
    color: #166df9 !important;
}

.fotoSliderArticle .slick-arrow.slick-prev,
.fotoSliderArticle .slick-arrow.slick-next {
    top: 200px !important;
    transform: 0 !important;
}

.fotoSliderArticle .slick-slide {
    display: flex !important;
    justify-content: center;
}

.fotoSliderArticle {
    height: 255px !important;
}

.fotoSliderArticle .slick-arrow.slick-prev {
    top: 36% !important;
}

.fotoSliderArticle .slick-arrow.slick-next {
    top: calc(36% + 25px) !important;
}

.slick-slide img {
    border-radius: 20px !important;
}

.imageModal .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}

.react-player__shadow {
    width: 1100px !important;
    height: 630px !important;
}