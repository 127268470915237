.date {
    background-color: #eef0f4;
    border-radius: 12px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
}

.name {
    margin-left: 20px;
}

.dataWrapper {
    display: flex;
}
