.block {
    width: 100%;
}

.titleDelete {
    display: flex;
    text-align: center;
}

.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.btnsRemoveContractModal {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.modalContainerRemoveContract {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.select {
    margin-bottom: 30px;
}
.select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
    padding-left: 20px !important;
}
