.main {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.main {
    justify-content: center;
}

.pageUpperButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.backButton {
    margin-bottom: 30px;
}

.saveContractButton {
    align-self: flex-end;
    margin-bottom: 30px;
}

.editorContainer {
    width: 720px;
    font-family: 'Times New Roman', Times, serif !important;
}

.editorContainer * {
    /* flex-grow: 1; */
    overflow: hidden;
    font-family: 'Times New Roman', Times, serif !important;
}
.rightSide {
    min-width: 750px;
    min-height: 35vh;
}
.leftSide {
    min-width: 300px;
    margin-right: 50px;
    padding-right: 10px;
    max-height: 69vh;
    min-height: 35vh;
    overflow-y: auto;
    position: sticky;
    top: 100px;

    &::-webkit-scrollbar-track {
        background-color: #ffffff !important;
    }

    &::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
        background-color: #dddddd !important;
        border: 3px solid #ffffff !important;
    }
}

.fullWidth {
    width: 100%;
}

.mt15 {
    margin-top: 15px;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modalButtons {
    margin-top: 40px;
    display: flex;
    width: 403px;
    justify-content: flex-end;
    gap: 20px;
}
.barcode {
    display: flex;
    justify-content: flex-end;
}
