.elParam {
    height: 54px;
    border-radius: 10px;
    padding-left: 20px;
    background-color: #f9f9f9 !important;
    cursor: not-allowed;
}

.hiddenInput {
    display: none;
}

.docsForm {
    margin-bottom: 20px;
    margin-top: 10px;
}

.paymentRules {
    cursor: pointer;
    color: #166df9;
}
.formBlock {
    min-height: 670px;
}
