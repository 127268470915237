.App {
    text-align: center;
}

#root {
    height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.inputPass input::-ms-reveal {
    display: none;
}

.ant-popover-inner {
    padding: 16px !important;
}

/* ----------------------SLIDER---------------------- */
.customSlider .slick-dots li button:before {
    content: '';
    background: #d6dae2;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: 1;
    transition: 0.3s all;
}

.customSlider .slick-dots li button:hover::before {
    background: #5b6b85;
}

.customSlider .slick-dots .slick-active button:before {
    background: #166df9 !important;
}

.customSlider .slick-dots {
    bottom: -20px;
}

.customSlider .slick-arrow {
    width: 50px;
    height: 50px;
    border: 1px solid #dbdde1;
    border-radius: 50%;
}

.customSlider .slick-arrow::before {
    content: url('/src/assets/arrowPagination.svg');
    transition: 0.3s all;
}

.customSlider .slick-arrow.slick-next {
    right: -75px;
}

.customSlider .slick-arrow.slick-prev {
    left: -75px;
    transform: rotate(180deg);
}

/* ----------------------END-SLIDER---------------------- */

/* ----------------------NOTIFICATIONS---------------------- */
.ant-notification-notice {
    border-radius: 20px;
    width: 450px;
}

.ant-notification-notice-content {
    min-height: 75px;
}

.ant-notification-notice-message {
    margin-inline-start: 103px !important;
}

.ant-notification-notice-description {
    margin-inline-start: 103px !important;
}

/* ----------------------END-NOTIFICATIONS---------------------- */

.ant-modal-wrap {
    background-color: rgba(119, 132, 171, 0.85);
}

.ant-modal-content {
    padding: 0 !important;
    border-radius: 15px !important;
}

/* стили дли движа с конструктором договоров */
.empty-value {
    border: 2px solid #c41e1e;
    padding: 2px;
    display: contents;
    color: #e63232;
    background-color: #e9f5ff;
    font-style: italic;
}

.custom-text-color {
    color: #007bff;
    font-weight: 600;
}

.no-break {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
    line-height: 1.2 !important;
    overflow: visible !important;
}

/* /стили дли движа с конструктором договоров */
