.link {
    font-size: 16px;
    line-height: 24px;
    color: #166df9;
    cursor: pointer;
}

.row {
    display: inline-block;
}
