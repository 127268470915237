.title {
    font-size: 36px;
    line-height: 40px;
    font-weight: 800;
}

.main {
    font-size: 56px;
    line-height: 66px;
}

.medium {
    font-size: 36px;
    line-height: 48px;
}

.middle {
    font-size: 26px;
    line-height: 30px;
}

.small {
    font-size: 20px;
    line-height: 24px;
}

.extra {
    font-size: 70px;
}

.bold {
    font-weight: 800;
}

.wordWrapBW {
    word-wrap: break-word;
}
