.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.modalContainer {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.elParamWrapper {
    width: 400px;
}

.elParam {
    width: 400px;
    height: 54px;
    border-radius: 10px;
}

.wrapper {
    width: 100%;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 60px;
}

.container {
    width: 730px;
}

.userData {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.userNameWrapper {
    margin-left: 30px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.rowLeft {
    display: flex;
    align-items: center;
}

.text {
    width: 200px;
}

.photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.btnUpload {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
}

.uploadWrapper {
    display: flex;
    align-items: center;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
    background-color: rgb(133, 137, 151);
    color: #fff;
    opacity: 0.5;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.mainFIO {
    width: 620px;
    word-wrap: break-word;
}
.secondaryFIO {
    width: 420px;
    word-wrap: break-word;
}
