.top_FindInputs {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: inherit;
}

.buttonsBlock {
    display: flex;
}

.top_FindInput_item {
    width: 540px;
}

.bucket {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.175s all;
}

.bucket:hover {
    background-color: #e6e6e6;
}

.bucket:active {
    background-color: #b8b8b8;
}

.nameWrapper {
    display: flex;
    align-items: center;
}

.nameWrapper img {
    margin-right: 16px;
}

.list {
    max-width: 430px;
}

.listEl {
    position: relative;
    margin-left: 15px;
}

.listEl::before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #adb2c4;
    border-radius: 50%;
    position: absolute;
    left: -20px;
    top: 10px;
}

.button_link {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
}

.button {
    padding-left: 50px;
    padding-right: 50px;
}
