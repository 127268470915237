.main {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 30px;
    margin-top: 30px;
}

.leftBtn {
    width: 80px;
    height: 54px;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #e6e6e6 !important;
    border-right: none;
    box-shadow: none;
}

.rightBtn {
    width: 80px;
    height: 54px;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #e6e6e6 !important;
    border-left: none;
    box-shadow: none;
}

.inputCounter {
    height: 54px;
    width: 80px;
    text-align: center;
    border-radius: 0;
    font-size: 16px;
    padding: 0;
}

.rightBtn:active,
.rightBtn:focus,
.rightBtn:hover {
    box-shadow: none !important;
    outline: none !important;
}

.radioBlock {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.radioItem {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.radioInput {
    margin-left: 20px;
    width: 140px;
    height: 54px;
}

.mt15 {
    margin-top: 15px;
}
