.wrapper {
    background-color: #f2f4f8;
    width: 255px;
    height: 277px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: 30px;
}
.questionImg {
    margin-top: 29px;
}
.title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
    max-width: 110px;
    margin-top: 18px;
    margin-bottom: 24px;
    text-align: center;
}
