.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 110px;
    padding-bottom: 80px;
    height: 1200px;
}

.container {
    display: flex;
    width: 555px;
}

.halfScreen {
    width: 50%;
    overflow: hidden;
}

.elParam {
    width: 400px;
    height: 54px;
    border-radius: 10px;
}

.font16 {
    font-size: 16px !important;
    line-height: 16px !important;
}

.logoWrapper {
    position: absolute;
    margin-top: 150px;
    max-width: 90%;
    display: flex;
    justify-content: center;
}

.logoWrapper > img {
    max-width: 90%;
}

.captchaErrorMessage {
    position: relative;
    bottom: -90px;
    left: -300px;
    color: #ff4d4f;
    display: none;
}
.captchaErrorMessage.show {
    display: inline;
    white-space: nowrap;
}
