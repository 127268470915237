.wrapper {
    padding-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    position: relative;
}

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 800;
    margin-right: 14px;
}

.page:hover {
    background-color: #e6e6e6;
}

.activePage {
    color: #fff !important;
    background-color: #166DF9 !important;
}

.arrow {
    position: absolute;
    border: none;
    right: -64px;
}

.leftArrow {
    transform: rotate(180deg);
    left: -64px;
}