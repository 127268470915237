.statusWrapper {
    height: 40px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 11px;
}

.icon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}