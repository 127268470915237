.cardItem {
    width: 350px;
    height: 140px;
    border: 1px solid #dbdde1;
    border-radius: 14px;
    position: relative;
    box-sizing: border-box;
    margin-top: 30px;

    display: flex;
    align-items: center;
}

.cardPrice {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.cardTitle {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    max-width: 180px;
    width: 180px;
}

.cardTop {
    display: flex;
    gap: 10px;
    padding-right: 30px;
    padding-left: 30px;
    justify-content: space-between;
}

.cardSelect {
    width: 280px !important;
    margin: 0 auto;
    position: absolute;
    margin-left: 30px;
    bottom: 0;
    margin-bottom: 18px;
}

.select {
    width: inherit !important;
    margin-top: 10px;
}

.select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
}

.infoBlock {
    background-color: #eef0f4;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-right: 30px;
    right: -20px;
    top: -60px;
    margin-top: 70px;
    color: #166df9;
}

.cardActive {
    border: 1px solid #166df9;
    outline: #166df9 solid 1px;
}

.cardItem:hover {
    cursor: pointer;
}
