.dateWrapper {
    display: flex;
}

.date {
    background-color: #eef0f4;
    border-radius: 12px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
}
.text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    margin-bottom: 24px;
}
