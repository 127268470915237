.messagesTable {
    width: 100%;
}

.messagesTable .ant-table-cell {
    padding: 30px 16px !important;
}

.messagesTable th.ant-table-cell {
    background-color: #fff !important;
    border-top: 2px solid #000 !important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope';
}

.messagesTable th.ant-table-cell::before {
    background-color: #fff !important;
}

.messagesTable .ant-table-column-sorters {
    padding-right: 50px;
}