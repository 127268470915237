.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    margin-top: -20px;
    position: relative;
}

.container {
    padding-top: 80px;
    width: 1110px;
    display: flex;
    align-items: center;
    position: relative;
}
