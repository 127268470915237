.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    border-radius: 0 0 20px 20px;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 333;
}

.header {
    background: #fff;
    display: flex;
    width: 1100px;
    align-items: center;
    justify-content: center;
}

.border {
    border-bottom: 1px solid #dbdde1;
}

.btns {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 24px;
    padding: 0 30px 0 50px;
}

.logo {
    cursor: pointer;
}
