.wrapper {
    border: 1px solid #dbdde1;
    border-radius: 20px;
    width: 348px;
    height: fit-content;
    padding-bottom: 30px;
    overflow: hidden;
}

.container {
    padding: 30px 30px;
    width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 300px;
}

.topBlockCard {
    min-height: 160px;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    margin-top: 10px;
    position: relative;
    bottom: -20px;
}

.selectTitle {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
}

.select {
    width: 100%;
    margin-bottom: 30px;
}

.select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
}

.title {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    min-height: 56px;
}

.price {
    font-size: 36px;
    line-height: 28px;
    font-weight: 800;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 30px;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    min-height: 110px;
}

.bottomBlock {
    margin-top: 10px;
}

.maxParticipants {
    min-height: 40px;
    margin-bottom: 10px;
}
.disabledCross {
}
