.formBlock {
    border-radius: 20px;
}

.elParam {
    height: 54px;
    border-radius: 10px;
    padding-left: 20px;
    background-color: #f9f9f9 !important;
}

.select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
    padding-left: 20px !important;
}
