.cardsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
}

.noSlider {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    width: 100%;
    margin-bottom: 40px;
}
