.wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
}

.img {
    margin-right: 17px;
}

.maxParticipants {
    color: #1677ff;
    font-weight: 800;
}