.titleBlock {
    width: 100%;
    margin-top: 50px;
}

.titleInner {
    width: 320px !important;
    margin: 0 auto;
}

.reactDadata {
    width: 100%;
    margin-top: 50px;
}

.reactDadataBox > input {
    border-color: #166df9;
    height: 54px !important;
    border-radius: 10px;
    padding-left: 20px;
    background: #f9f9f9;
}