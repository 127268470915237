.cardsContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 80px;
    height: 220px;
}
.cardsContainerRow {
    justify-content: center;
    flex-direction: row;
}
