.newsContainer {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    justify-content: space-between;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsContainerEvenly {
    justify-content: space-evenly;
}
