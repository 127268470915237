.projectsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 30px 0px;
    width: 550px;
    max-height: 300px;
}
.project {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 40px;
    width: 500px;
    min-height: 82px;
    border-radius: 10px;
    padding-left: 10px;
}

.project:hover {
    background: #fafafa;
    transition-duration: 0.3s;
}

.selectedProject {
    background: #f0efef;
}
.selectedProject:hover {
    background: #f0efef;
    transition-duration: 0.3s;
}

.scroll {
    overflow-y: auto;
    overflow-x: hidden;
}

.scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eef0f4;
}

.scroll::-webkit-scrollbar {
    width: 6px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #2f5686;
}
.cursorNotAllowed {
    cursor: not-allowed;
}
