.addProjectTable {
    width: 100%;
}

.addProjectTable .ant-table-cell {
    padding: 30px 16px !important;
}

.addProjectTable th.ant-table-cell {
    background-color: #fff !important;
    border-top: 2px solid #000 !important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope';
    user-select: none;
}

.addProjectTable th.ant-table-cell::before {
    background-color: #fff !important;
}

.addProjectTable th.ant-table-cell {
    white-space: nowrap;
}

.ant-popover {
    border: 1px solid #dbdde1;
    border-radius: 10px !important;
}

.ant-popover-inner {
    padding: 20px 25px !important;
    background: #ffffff !important;
    /* border: 1px solid #dbdde1 !important; */
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 10px !important;
}

.ant-popover-inner > div > div > p {
    margin: 0;
    padding: 0;
}

.ant-popover-inner > div > div > p:nth-child(2) {
    margin-top: 15px;
}
