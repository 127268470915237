.fileBox {
    display: flex;
}
.fileName {
    font-size: 16px;
    color: rgba(22, 109, 249, 1);
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fileIcon {
    margin-right: 10px;
    min-width: 16px;
}

.fileExtension {
    font-size: 16px;
    color: rgba(22, 109, 249, 1);
    font-weight: 400;
    font-size: 16px;
}
