.blocksWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.spinner {
    margin: 0 auto;
}

.last {
    margin-bottom: 60px;
}
