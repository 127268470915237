.left__block {
    width: 635px;
    display: flex;
    flex-direction: column;
}

.event {
    display: flex;
    flex-direction: row;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    height: 170px;
    box-shadow: 0px 4px 40px 0px #0000000f;
    margin-bottom: 20px;
}

.event.clickable:hover {
    cursor: pointer;
}

.leftSide {
    width: 170px;
    border-right: 1px solid #dbdde1;
}
.leftSideDate {
    padding-top: 30px;
    padding-left: 30px;
}

.rightSide {
    padding-top: 30px;
    padding-left: 30px;
    width: 424px;
}

.day {
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
}

.month {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.dayWeek {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8f93a2;
    text-transform: capitalize;
}

.description {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: #858997;
    margin-top: 12px;
    height: 88px;
    width: 424px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.description:hover {
    color: #000;
    transition: 0.3s;
}
