.wrapper {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    width: 1100px;
    display: flex;
    justify-content: left;
    margin: 0 auto;
    padding-bottom: 30px;
}

.breadcrumb > nav {
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.main_wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;
}
