.nameWrapper {
    display: flex;
    align-items: start;
    cursor: pointer;
    flex-direction: column;
    max-width: 800px;
}

.wordWrap {
    overflow-wrap: break-word;
}

.nameWrapper img {
    margin-right: 16px;
}

.button_link {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.selected {
    border-color: #1677ff;
}

.dataTimeInner {
    min-width: 120px;
    text-align: right;
    display: flex;
    flex-direction: row;
}

.button_link {
    padding: 0 !important;
    text-align: left;
}