.inner {
    margin-top: 40px;
    border: 1px solid #dbdde1;
    padding: 35px 45px 0 45px;
    width: 1015px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    border-radius: 20px 20px 20px 0;
}

.inner.outgoing {
    border-radius: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    background-color: #eef0f4;
}

.title {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    width: 100%;
    word-wrap: break-word;
}

.whiteArrow {
    position: absolute;
    left: -1px;
    bottom: -18px;
}

.greyArrow {
    position: absolute;
    right: -1px;
    top: -18px;
}

.mainText {
    margin-top: 20px;
    width: 100%;
    word-wrap: break-word;
}

.author {
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid #dbdde1;
    display: flex;
    gap: 24px;
    align-items: center;
    width: 100%;
    padding-bottom: 25px;
}

.authorName {
    max-width: 42%;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-start;
}

.authorDataTime {
    font-size: 14px;
    line-height: 20px;
    color: #63676d;
    background-color: #eef0f4;
    padding: 6px 12px;
    border-radius: 12px;
    flex-shrink: 0;
    width: max-content;
    align-self: flex-start;
}

.authorDataTime.outgoing {
    color: #63676d;
    background-color: #fff;
}

*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.attachments {
    display: flex;
    flex-direction: column;
    width: 38%;
}
