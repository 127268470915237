.top_FindInputs {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: inherit;
}

.top_FindInput_item {
    width: 1130px;
}

.top_buttons {
    margin-bottom: 30px;
}

.iconWrapper {
    display: flex;
    align-items: center;
}
