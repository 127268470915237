.container {
    width: 1100px;
    display: flex;
    justify-content: left;
    margin: 0 auto;
    margin-top: 30px;
}

.breadcrumb > nav {
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.main {
    display: flex;
    margin-top: 50px;
    margin-bottom: 80px;
    gap: 45px;
}

.rightBlockImg {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
}

.title {
    font-weight: 800;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 30px;
}

.subTitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.articleMain {
    border-radius: 20px;
    height: 480px;
    width: 730px;
}

.mainBlockImg {
    position: relative;
}

.playBlock {
    position: absolute;
    top: calc(50% - 55px);
    left: calc(50% - 55px);
    cursor: pointer;
}

.mainBlockText1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 50px;
    margin-bottom: 60px;
}

.link {
    color: #166df9;
    text-decoration: underline;
}

.mainBlockText3 {
    margin-top: 40px;
}

.docs {
    background-color: #eef0f4;
    border-radius: 20px;
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
}

.docsTitle {
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
}

.download {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 20px;
    cursor: pointer;
    transition: 0.175s all;
    gap: 10px;
}

.mainBlockSlider {
    margin-top: 80px;
    width: 1110px;
}

.titleBlockSlider {
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
}

.cardsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.sliderBottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 50px;
}

.social {
    display: flex;
    align-items: center;
    gap: 20px;
}

.icon {
    width: 37px;
    height: auto;
    margin-left: 7px;
}

.socialIcon {
    display: flex;
}

.leftBlock {
    flex: 2;
}

.rightBlock {
    flex: 1;
}

.videoWrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 40px 0;
}

.imageInModal {
    height: 80vh;
    display: block;
    border-radius: 20px;
}

.react-player {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    background-size: cover;
}

.videoBox {
    width: inherit;
}

.typeWebinar {
    margin-bottom: 30px;
}