.infoContainer {
    margin: 30px 0 0 0;
    width: 1110px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.infoElement {
    width: 350px;
    height: 170px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    border-radius: 14px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.infoElementBigText {
    font-size: 40px;
    font-weight: 600;
    color: white;
    line-height: 40px;
}

.infoElementSmallText {
    font-size: 18px;
    font-weight: 400;
    color: white;
    line-height: 26px;
}
