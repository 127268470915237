.container {
    width: 1100px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
}

.breadcrumb__btn {
    padding: 0 !important;
    font-size: 16px;
    font-weight: 400;
}
