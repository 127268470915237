.divider {
    border-bottom: 1px solid #dbdde1;
    width: 100%;
}

.rowDividerWrapper {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.rowDivider {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #adb2c4;
}
