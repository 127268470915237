.customTabs .ant-tabs-nav::before {
    border-bottom: none;
}

.customTabs .ant-tabs-nav {
    margin-bottom: 20px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
}

.customTabs .ant-tabs-tab {
    color: #8f93a2;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    flex: 1;
    text-align: center;
}

.customTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
}

.customTabs .ant-tabs-nav-wrap {
    flex: 1;
}

.customTabs .ant-tabs-nav-list {
    display: flex;
    width: 100%;
}

.customTabs .ant-tabs-nav-operations {
    display: none !important;
}
