.container {
    width: 1100px;
    margin: 0 auto;
}

.top_content {
    display: grid;
    gap: 30px;
    grid-template-columns: 350px auto;
    margin-bottom: 40px;
}

.title {
    font-size: 36px;
    font-weight: 800;
    line-height: 46px;
    margin: 0;
    padding: 0;
}

.descr {
    margin: 0;
    padding: 0;
}

.gallery {
    display: flex;
    width: 1140px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
}

.galleryBlock {
    margin-top: 30px;
    display: flex;
    width: 350;
}

.btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 80px;
}
