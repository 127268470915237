.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 710px;
    padding-top: 70px;
}

.container {
    display: flex;
    width: 555px;
}

.halfScreen {
    width: 50%;
    overflow: hidden;
    max-height: calc(100% + 100px);
    position: absolute;
}

.elParam {
    width: 400px;
    height: 54px;
    border-radius: 10px;
}

.elParamWrapper {
    width: 400px;
}

.font16 {
    font-size: 16px !important;
    line-height: 16px !important;
    min-height: 18px;
}

.logoWrapper {
    position: absolute;
    margin-top: 150px;
    max-width: 90%;
    display: flex;
    justify-content: center;
}

.logoWrapper > img {
    max-width: 90%;
}

.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.modalContainer {
    width: 410px;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
