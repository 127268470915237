.rateWrapper {
    border-radius: 10px;
    display: inherit;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px;
    border: 1px solid;
    font-family: 'Manrope';
}

.start {
    color: #ed8864;
    border-color: #ed8864;
}

.startMax {
    color: #2f5686;
    border-color: #2f5686;
}

.startLight {
    color: #1ab35e;
    border-color: #1ab35e;
    white-space: nowrap;
}

.documental {
    color: #8ab9e8;
    border-color: #8ab9e8;
}
