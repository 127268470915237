.tableWithCustomLastTh {
    th:last-child {
        text-align: right !important;
    }
}

#propertiesVersionForm > div > div > div > label {
    height: 24px;
}
#customerDetailsVersionForm > div > div > div > label {
    height: 24px;
}

.contractsTable th.ant-table-cell {
    background-color: #fff !important;
    border-top: 2px solid #000 !important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope';
    user-select: none;
}

.contractsTable th.ant-table-cell::before {
    background-color: #fff !important;
}
