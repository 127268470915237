.wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    width: 100%;
}

.titleRow {
    width: 100%;
}

.typeWebinar {
    margin-bottom: 30px;
}

.breadcrumbs {
    width: 100%;
    margin-bottom: 39px;
    margin-top: 20px;
}

.breadcrumb__btn {
    font-size: 16px;
    font-weight: 400;
    padding-left: 0;
    margin-bottom: 40px;
}

.wrapperMain {
    margin-top: 0;
}
.backButton {
    width: 100%;
}

.videoChatWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 40px 0;
}

.videoBox {
    height: 480px;
    width: 730px;
    border-radius: 20px;
}

.toLecturesButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 60px;
}
