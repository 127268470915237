.wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.underTextWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
