.block {
    width: 100%;
}

.smallColumn {
    max-width: 80px;
    white-space: break-spaces;
}

.backButton {
    position: absolute;
    left: 0;
    top: 40px;
}

.icon {
    margin-right: 10px;
}

.popoperContent {
    padding: 0;
}

.popoverContentBtn {
    cursor: pointer;
    color: #8f93a2;
    transition: 0.175s all;
}

.popoverContentBtn:hover {
    color: black;
}

.flex {
    display: flex;
    align-items: center;
}

.jcSb {
    justify-content: space-between;
}
.emailParticipantWrapper {
    width: 400px;
}

.emailParticipant {
    width: 407px;
    height: 54px;
    border-radius: 10px;
}
.projectPropertyVersionNameWrapper {
    width: 440px;
}

.projectPropertyVersionName {
    width: 440px;
    height: 54px;
    border-radius: 10px;
}
.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.titleInvite {
    display: flex;
    text-align: center;
}
.modalContainer {
    width: 1100px;
    height: 860px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.btnsCopyProjectModal {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.StarIcon {
    width: 42px;
}
.mr0 {
    margin-right: 0;
}
.infoPopoperContent {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 365px;
}
.infoPopoverRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
}
.infoPopoverRowName {
    width: 180px;
    margin-right: 10px;
}
.infoPopoverRowData {
    width: 175px;
}
.faded {
    opacity: 0.2;
}

.tableWrapper {
    width: 1000px;
    margin-bottom: 20px;
}
