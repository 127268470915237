.bucket {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.175s all;
    top: 40px;
    right: 0;
    user-select: none;
}
.round {
    border-radius: 50%;
}

.bucket:hover {
    background-color: #e6e6e6;
}

.bucket:active {
    background-color: #b8b8b8;
}
