.info {
    position: absolute;
    align-items: center;
    display: flex;
    top: 50px;
    right: 0;
}

.projectStatus {
    margin-right: 18px;
}

.infoMessage {
    max-width: 185px;
    font-size: 14px;
}

.statusBlock {
    display: flex;
    align-items: center;
    margin: 0 12px;
    border: 1px solid #dbdde1;
    border-radius: 10px;
    padding: 20px 25px;
}
.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalInner > div {
    border: none;
}
