.wrapper {
    width: 255px;
    height: 210px;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.name {
    color: #63676d;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-align: center;
}

.img {
    height: 120px;
    display: flex;
    align-items: center;
}

.img img {
    max-height: 100%;
    max-width: 100%;
}
