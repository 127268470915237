.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.ql-align-right {
    text-align: right;
}

.ql-align-left {
    text-align: left;
}

.ql-align-justify {
    text-align: justify;
}

.quill {
    margin-bottom: 30px;
}
