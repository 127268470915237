.wrapper {
    width: 350px;
    cursor: pointer;
    transition: 0.3s all;
    color: #000;
}
.defaultCursor {
    cursor: default;
}

.img {
    border-radius: 20px;
    width: 350px;
    height: 352px;
}

.textContainer {
    background-color: #fff;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 28px 30px 0 30px;
    height: 332px;
    margin-top: -36px;
    position: relative;
}

.name {
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 10px;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}
