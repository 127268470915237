.personSliderReviews .slick-arrow.slick-prev {
    top: 200px !important;
}

.personSliderReviews .slick-slide {
    display: flex !important;
    justify-content: center;
}

.personSliderReviews {
    height: 450px !important;
}

.personSliderReviews .slick-slide img {
    border-radius: 50% !important;
}

.copmanySliderReviews .slick-arrow.slick-prev {
    top: 80px !important;
}
