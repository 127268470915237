.navbarEl {
    display: flex;
    align-items: center;
}

.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    position: relative;
    border: 1px solid #dbdde1;
    position: relative;
}

.circleInner {
    position: absolute;
    top: 20px;
    left: 25.5px;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
}

.stepActive {
    background-color: #166df9;
    color: #fff;
}

.circle::after {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    top: 17.6px;
    right: 25px;
}

.text {
    margin-left: 12px;
}
