.container {
    width: 330px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.img {
    width: 65px;
    height: 65px;
    padding: 17px 17px;
    background-color: #eef0f4;
    margin-bottom: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.title {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
}
