.statusWrapper {
    height: 40px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 11px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    min-width: 79px;
    justify-content: center;
    margin-right: 10px;
}

.icon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.main {
    display: flex;
}