.paymentTable {
    width: 100%;
}

.paymentTable thead .ant-table-cell {
    padding: 15px 15px !important;
}
.paymentTable tbody .ant-table-cell {
    padding: 30px 16px !important;
}

.paymentTable th.ant-table-cell {
    background-color: #fff !important;
    border-top: 2px solid #000 !important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope';
}

.paymentTable th.ant-table-cell::before {
    background-color: #fff !important;
}
