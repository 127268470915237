.wrapper {
    width: 350px;
    height: 300px;
    border: 1px solid #dbdde1;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s all;
    color: #858997;
}

.container {
    margin: 30px;
}

.dateWrapper {
    display: flex;
}

.date {
    background-color: #eef0f4;
    border-radius: 12px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    color: #63676d !important;
}

.text {
    margin-top: 26px;
    max-width: 290px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-align: center;
}

.wrapper:hover {
    color: #000;
}
