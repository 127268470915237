.bucket {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.175s all;
}

.bucket:hover {
    background-color: #e6e6e6;
}

.bucket:active {
    background-color: #b8b8b8;
}

.nameWrapper {
    display: flex;
    align-items: center;
}

.nameWrapper img {
    margin-right: 16px;
}

.list {
    max-width: 430px;
}

.listEl {
    position: relative;
    margin-left: 15px;
}

.listEl::before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #adb2c4;
    border-radius: 50%;
    position: absolute;
    left: -20px;
    top: 10px;
}

.popoper {
    display: flex;
    justify-content: flex-end;
}

.popoperItem {
    cursor: pointer;
    color: #8f93a2;
}

.popoperItemActive {
    color: #000;
}

.popoperItem:hover {
    color: #000;
    transition: 0.3s all;
}

.topBlock {
    display: flex;
    width: 100%;
}

.topInputBlock {
    margin-left: 20px;
    width: 100%;
}

.btnsRemoveParticipantModal {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.modalContainer {
    width: 410px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.titleInvite {
    display: flex;
    text-align: center;
}
