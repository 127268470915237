.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.blockInner {
    width: 450px;
}

.blockRightImg {
    margin-top: 40px;
}

.title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 800;
    margin: 0;
}
