.container {
    width: 1100px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
}

.breadcrumb__btn {
    padding: 0 !important;
    font-size: 16px;
    font-weight: 400;
}

.inner {
    margin-top: 40px;
    width: 100%;
    margin-bottom: 100px;
}

.title {
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
}

.form {
    margin-top: 30px;
}

.inputInner {
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    width: 100%;
    background: #f9f9f9;
}

.buttonBlock {
    margin-top: 20px;
}

.selectBlock {
    margin-top: 30px;
}

.select {
    width: 100%;
}

.select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
}