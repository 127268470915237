.statusWrapper {
    height: 26px;
    width: 80px;
    display: flex;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
}
