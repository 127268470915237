.text {
    font-size: 18px;
    line-height: 28px;
}

.mid {
    font-size: 16px;
    line-height: 26px;
}

.small {
    font-size: 14px;
    line-height: 18px;
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
