.customCalendar {
    width: 445px;
    height: 400px;

    .ant-picker-content th {
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 12px;
        color: #7e818c;
        padding-bottom: 20px;
        text-transform: uppercase;
        height: 20px !important;
    }

    .ant-picker-panel {
        background: #f2f4f8;
        border-radius: 0 0 20px 20px;
        padding: 20px;
    }

    .simpleDate {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: rgba(196, 196, 196, 1);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        top: 2px;
        left: 12px;
    }
    .ant-picker-cell-in-view .simpleDate {
        color: #000;
    }

    .eventDay {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 2px;
        left: 12px;
        width: 35px;
        height: 35px;
        background: #fff;
        border-radius: 50%;
        color: #000;

        &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: #166df9;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
        }
    }

    .trueSelectedDate {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 2px;
        left: 12px;
        width: 35px !important;
        height: 35px !important;
        border-radius: 50% !important;
        background-color: #166df9;
        color: #fff !important;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
    .ant-picker-cell {
        cursor: default;
    }
    .selectableDate {
        cursor: pointer;
    }
}
