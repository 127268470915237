.newMessageContactsPage {
    display: flex !important;
    column-gap: 30px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.newMessageContactsPage .ant-form-item {
    margin: 0px;
}

.newMessageContactsPage .textAreaFormItem.ant-form-item {
    margin: 0px;
}
.newMessageContactsPage .newMessageContactsPage-smallError {
    width: 320px !important;
}

.captchaContainerContactsPage .smart-captcha {
    margin: 0px;
    width: 399px;
}
