.wrapper {
    width: 100%;
    height: 200px;
    padding-top: 90px;
    background-color: #f2f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20px 20px;
}

.text {
    font-size: 56px;
    line-height: 56px;
    color: #000;
    font-weight: 800;
}

.blueText {
    color: #2f5686;
}
