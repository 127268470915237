.stepsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 23px;
}
.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepNum {
    margin: 12px;
}
.arrowWrapper {
    height: 265px;
}
.arrow {
    margin-top: 90px;
}
