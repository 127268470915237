.search {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    width: 100%;
    height: 54px;
    margin-bottom: 30px;
}

.filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.select {
    width: 100%;
    margin-bottom: 0;
}
.select > div {
    background-color: #f9f9f9 !important;
    height: 54px !important;
    padding-top: 11px !important;
    border-radius: 10px !important;
}
.gallery {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
}

.galleryBlock {
    margin-top: 30px;
}
