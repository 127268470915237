.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.container {
    width: 1100px;
}

.breadcrumb > nav {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    margin-top: 30px;
}

.search {
    margin-top: 30px;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}