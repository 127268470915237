.webinarContentLabel {
    width: 100%;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 30px 0;
}

.webinarDescr {
    margin-bottom: 20px;
    width: 100%;
}

.m0 {
    margin: 0;
}