.wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    width: 100%;
}

.breadcrumbs {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 20px;
}
