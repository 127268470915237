.wrapper {
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 30px 50px 0;
    width: 1008px;
    margin-bottom: 30px;
}

.noProjects {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.noProjectsTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.noProjectsImg {
    width: 178px;
}

.noProjectsBtn {
    margin-bottom: 50px;
}