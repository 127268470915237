.wrapper {
    display: flex;
    align-items: flex-start;
}

.img {
    margin-top: 5px;
    margin-right: 22px;
}

.title {
    color: #fff;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
}
