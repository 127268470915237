.regForm .ant-form-item {
    margin-bottom: 17px !important;
}

.regForm .ant-form-item-label {
    padding-bottom: 0 !important;
}

.regForm .ant-form-item-required::before {
    content: '' !important;
}

.regForm .ant-form-item-required::after {
    content: '*' !important;
    display: inline-block !important;
}

.smart-captcha {
    width: min-content;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 12px;
}

.captchaContainerError .smart-captcha {
    border: 1px solid #ff4d4f;
}
