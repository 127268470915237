.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
    min-height: 560px;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.elParam {
    width: 400px;
    height: 54px;
    border-radius: 10px;
}

.elParamWrapper {
    width: 400px;
}
