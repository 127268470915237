.footerWrapper {
    height: 510px;
    background: #282c3b;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
}

.topWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}

.footer {
    margin-top: 70px;
    display: flex;
    width: 1100px;
    justify-content: space-between;
}

.pic {
    cursor: pointer;
    margin-top: 70px;
    max-width: 115px;
}

.container {
    width: 1100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 18px;
}

.bottom {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 137px;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #535662;
    color: #fff;
}

.logoWrapper {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: 55px;
    height: 28px;
}

.btns {
    max-height: 204px;
    width: 556px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 44px;
    column-gap: 73px;
    /* margin-left: 0px; */
    grid-template-rows: 1fr 1fr 1fr 1fr;
}

.addressWrapper {
    width: 350px;
}

.emailWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: -10px;
}

.email {
    font-weight: 800;
    font-size: 21px;
    line-height: 40px;
    color: #fff;
    text-decoration: none;
}

.icons {
    width: 202px;
    display: flex;
    justify-content: space-between;
}

.policity {
    color: #adb2c4;
    cursor: pointer;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalInner > div {
    border: none;
}

.footerLogos {
    width: 300px;
    position: relative;
    top: 40px;
}

.picCosmik {
    max-width: 200px;
}

.docs {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #adb2c4;
}

.docsBlock {
    flex-direction: column;
    align-items: flex-end;
}

.paymentRules {
    cursor: pointer;
}
