.inputWrapper {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    height: 54px;
    position: relative;
}

.findBtn {
    width: 64px;
    height: 100%;
    background-color: #166df9;
    position: absolute;
    right: 0;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.175s all;
    user-select: none;
}

.findBtn:hover {
    background-color: #1a61d3;
}

.findBtn:active {
    background-color: #1850ac;
}

.findBtn img {
    position: absolute;
    top: 17px;
    right: 16px;
}

.input {
    font-family: 'Manrope';
    font-size: 16px;
    width: calc(100% - 50px);
    background-color: #f9f9f9;
    border-radius: 10px;
    z-index: 2;
}

.input:focus {
    background-color: #fff;
}

.inputWrapper input {
    background-color: #f9f9f9 !important;
}
