.faqCollapse .ant-collapse-header {
    display: block !important;
    cursor: pointer;
}

.faqCollapse .ant-collapse-expand-icon {
    position: absolute;
    right: 18px;
    top: 0px;
    cursor: pointer;
}

.faqCollapse .ant-collapse-header-text {
    display: block;
}
