.container {
    display: flex;
    margin-top: 20px;
    height: 450px;
    width: 1100px;
}

.playContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 316px;
    height: 100%;
    background-color: #eef0f4;
    border-radius: 0 20px 20px 0;
    margin-left: -16px;
    /* background: linear-gradient(to left, #eef0f4, white 60%); */
}

.playContainer > * {
    margin-left: 8px;
}

.video {
    z-index: 2;
    height: 100%;
}

.play,
.stop {
    margin-bottom: 24px;
    cursor: pointer;
    border: #166df9 solid 2px;
    border-radius: 50%;
}

.title {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 800;
    text-align: center;
}

.text {
    font-size: 18px;
    line-height: 26px;
}

.video > video {
    height: 100%;
}

.videoContainer {
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.videoContainer img {
    width: 800px;
    height: 450px;
    max-height: 100%;
    z-index: 100;
}
