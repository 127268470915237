.wrapper {
    width: 100%;
    height: 851px;
    display: flex;
    justify-content: center;
    background-image: url('/src/assets/camera.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.container {
    width: 1110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.containerLeft {
    width: 1110px;
    margin-left: auto;
}

.mainText {
    padding: 122px 0 0 0;
    font-size: 56px;
    font-weight: 800;
    line-height: 66px;
    color: #fff;
    width: 60%;
}

.secondaryText {
    margin: 30px 0 15px 0;
    width: 700px;
    height: 138px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.infoContainer {
    margin: 30px 0 0 0;
    width: 1110px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.infoElement {
    width: 350px;
    height: 170px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    border-radius: 14px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.infoElementBigText {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    line-height: 40px;
}

.infoElementSmallText {
    font-size: 18px;
    color: #fff;
    line-height: 26px;
}

.modalInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
