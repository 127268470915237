.container {
    width: 1100px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}

.btns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 100px;
}

.next,
.prev {
    position: absolute;
    top: 20px;
}

.next {
    right: 20px;
}

.prev {
    left: 20px;
}

.jcc {
    justify-content: center;
}
.btnBlock {
    display: flex;
    width: 100%;
    justify-content: center;
}

.modalWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 400;
}

.modalContainer {
    width: 410px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.titleInvite {
    display: flex;
    text-align: center;
}

.btnsSaveChanges {
    display: flex;
    flex-direction: row;
    gap: 30px;
}
