.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 30px 0 50px 0;
}

.container {
    width: 1100px;
    display: flex;
    justify-content: left;
    margin: 0 auto;
    padding-bottom: 30px;
}

.breadcrumb > nav {
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
