.loader_main {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    cursor: wait;
    z-index: 9999;
}

.loader_item {
    position: absolute !important;
    left: 50%;
}
