.titleBlue {
    color: #2f5686;
}

.text {
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    max-width: 932px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.btn {
    width: 174px;
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 54px;
}

.reasonsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 60px;
    justify-content: flex-start;
    margin-bottom: 60px;
}
