.fileBox {
    display: flex;
    margin-top: 8px;
}
.fileName {
    margin-right: 14px;
    font-size: 16px;
}
.fileName.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 32px;
}
.fileDelete {
    border: 1px rgba(230, 230, 230, 1) solid;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 6px;
    margin-right: 14px;
}
.fileDelete:hover {
    cursor: pointer;
}
