@font-face {
    font-family: 'Manrope';
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-ExtraLight.eot');
    src: url('assets/fonts/static/Manrope-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-ExtraLight.woff') format('woff'),
        url('assets/fonts/static/Manrope-ExtraLight.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-ExtraLight.svg#Manrope-ExtraLight') format('svg');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-Light.eot');
    src: url('assets/fonts/static/Manrope-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-Light.woff') format('woff'),
        url('assets/fonts/static/Manrope-Light.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-Light.svg#Manrope-Light') format('svg');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-Regular.eot');
    src: url('assets/fonts/static/Manrope-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-Regular.woff') format('woff'),
        url('assets/fonts/static/Manrope-Regular.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-Regular.svg#Manrope-Regular') format('svg');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-Medium.eot');
    src: url('assets/fonts/static/Manrope-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-Medium.woff') format('woff'),
        url('assets/fonts/static/Manrope-Medium.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-Medium.svg#Manrope-Medium') format('svg');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-SemiBold.eot');
    src: url('assets/fonts/static/Manrope-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-SemiBold.woff') format('woff'),
        url('assets/fonts/static/Manrope-SemiBold.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-SemiBold.svg#Manrope-SemiBold') format('svg');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-Bold.eot');
    src: url('assets/fonts/static/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-Bold.woff') format('woff'),
        url('assets/fonts/static/Manrope-Bold.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-Bold.svg#Manrope-Bold') format('svg');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url('assets/fonts/static/Manrope-ExtraBold.eot');
    src: url('assets/fonts/static/Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/static/Manrope-ExtraBold.woff') format('woff'),
        url('assets/fonts/static/Manrope-ExtraBold.ttf') format('truetype'),
        url('assets/fonts/static/Manrope-ExtraBold.svg#Manrope-ExtraBold') format('svg');
}
