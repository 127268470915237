.main {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.title {
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
    align-self: center;
    width: 350px;
}
