.wrapper {
    width: 825px;
    margin-bottom: 20px;
    cursor: pointer;
}

.collapse {
    border: 1px solid #dbdde1;
    background-color: #fff;
    border-radius: 20px;
}

.panel {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    padding: 18px 0 24px 24px;
}

.panelItem {
    margin-top: 4px;
    margin-bottom: 0;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
}

.expandIcon {
    transition: 0.3s all;
}

.expandIconActive {
    transform: rotate(180deg);
}
