.wrapper {
    border: 1px solid #dbdde1;
    border-radius: 20px;
    padding: 30px 50px 40px;
    width: 438px;
    height: 440px;
    max-height: 440px;
}

.noEvents {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: inherit;
}

.noEvents img {
    width: 300px;
}

.noEventsTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    width: 390px;
}
