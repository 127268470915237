.button span {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}

.button {
    border-radius: 10px;
}

.grey:hover {
    box-shadow: 0px 10px 16px 0px rgba(0, 144, 255, 0.1);
    transition: 0.5s;
}

.grey {
    background-color: #eef0f4;
    color: #166df9;
}

.grey2 {
    background-color: #fff;
    color: #000;
    border: 2px solid #e6e6e6;
    box-shadow: none;
}

.grey2:hover {
    background-color: transparent !important;
    border-color: #166df9;
    box-shadow: none;
    color: #000 !important;
    transition: 0.5s;
}

.buttonIcon {
    width: 40px;
    position: absolute;
    top: 28%;
    left: 5%;
}

.noBorderRadius {
    border-radius: 0px;
}