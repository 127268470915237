.wrapper {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dbdde1;
    padding: 50px 0 80px;
}

.half {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
}

.dateWrapper {
    display: flex;
}

.date {
    margin-bottom: 10px;
}

.additionally {
    background-color: #eef0f4;
    border-radius: 12px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
}

.img {
    max-width: 100%;
    border-radius: 20px;
}

.author,
.tags {
    position: absolute;
    bottom: -55px;
}
