.customDatePicker {
    .ant-picker-panel-container {
        width: 445px;
        border-radius: 20px;
        border: 1px solid #dbdde1;
    }

    .ant-picker-date-panel {
        width: 100%;
    }

    .ant-picker-header {
        width: 100%;
    }

    .ant-picker-panel {
        background: #f2f4f8;
        border-radius: 0 0 20px 20px;
        width: 100%;
    }
    .ant-picker-cell-disabled::before {
        background: rgb(255, 255, 255) !important;
    }

    .ant-picker-content {
        width: 380px !important;
        margin: auto;
        margin-bottom: 20px;

        th {
            font-family: 'Manrope';
            font-weight: 500;
            font-size: 12px;
            color: #7e818c;
            padding-bottom: 15px;
            padding-top: 20px;
            text-transform: uppercase;
        }
    }

    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        order: 1;
        width: 50px;
        height: 50px;
        border: 1px solid #dbdde1 !important;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        transition: 0.3s all !important;

        .ant-picker-next-icon,
        .ant-picker-prev-icon {
            width: 15px;
            height: 15px;
            background-image: url('../../assets/arrowPagination.svg') !important;
            background-size: cover !important;
            transform: none;

            &:before {
                content: none;
            }
        }

        .ant-picker-prev-icon {
            transform: rotate(180deg);
        }
    }

    .ant-picker-header-next-btn {
        margin-right: 45px;
    }

    .ant-picker-header-prev-btn:hover,
    .ant-picker-header-next-btn:hover {
        background: #e9ebef;
    }

    .ant-picker-header-view {
        display: flex;
        margin-left: 40px;

        button {
            pointer-events: none;
            text-transform: capitalize;
            font-family: 'Manrope';
            font-weight: 600;
            font-size: 18px;
        }
    }

    .ant-picker-cell-today .ant-picker-cell-inner::before {
        border-radius: 50% !important;
    }

    .ant-picker-cell-inner {
        min-width: 35px !important;
        height: 35px !important;
        padding-top: 5px;
        border-radius: 50% !important;
        margin-top: 5px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
        width: 35px !important;
        height: 35px !important;
        padding-top: 5px;
    }

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn,
    .ant-picker-footer {
        display: none;
    }
}
